import "../../css/pages/404.scss";
import BasePage from "./base";

class PageNotFoundPage extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
    }
}

new PageNotFoundPage();
